.container {
  padding: 5px 15px;
  text-align: center;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  background-color: rgba(0, 0, 255, 0.034);
  border-color: rgba(0, 0, 255, 0.199);
  justify-content: center;
  align-items: center;

  >p {
    color: blue;
    text-align: center;
    word-break: keep-all;
  }

  &[arial-status-type='Warning'] {
    background-color: rgba(255, 166, 0, 0.068);
    border-color: rgba(255, 166, 0, 0.253);

    >p {
      color: rgb(211, 139, 5);
    }
  }

  &[arial-status-type='Success'] {
    background-color: rgba(0, 128, 0, 0.048);
    border-color: rgba(0, 128, 0, 0.24);

    >p {
      color: green;
    }
  }

  &[arial-status-type='Error'] {
    background-color: rgba(255, 0, 0, 0.055);
    border-color: rgba(255, 0, 0, 0.13);

    >p {
      color: red;
    }
  }
}