.invoice-list-item-bg {
  background-color: #E0E0E0;
  text-align: left;
  cursor: pointer;
}

.invoice-list-item-pix {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}