.workspace-header-bg {
  background: linear-gradient(45deg, #00708d 0%, #16a3b8 100%);
  height: 5vh;
}

.header-logo-kinto {
  max-height: 3vh;
}

.workspace-content {
  height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.workspace-footer {
  height: 3vh;
  padding: 0;
}

.ant-menu-item {
  margin: 0 !important;
}

.ant-layout-sider {
  width: fit-content
}

.ant-menu-vertical {
  border-right: none
}

.header-icon {
  font-size: 2vh;
  color: white;
}

.ant-layout-header > .ant-row > .ant-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.version {
  position: absolute;
  color: white;
}