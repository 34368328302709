.modal-pix-generate {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.modal-body::-webkit-scrollbar {
  width: 4px;               /* width of the entire scrollbar */
}

.modal-body::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #d5d5d5;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #d5d5d5;  /* creates padding around scroll thumb */
}

.modal-body {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.pix-icons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  justify-content: center;
}

.pix-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
}