.liveness-workspace {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.liveness-card-info { 
  grid-area: info;
  text-align: left;
  margin-bottom: 10px;
}

.liveness-card-start-button { grid-area: start-button }

.liveness-card {
  padding: 20px;
  width: 50%;
  max-width: 350px;
  display: grid;
  background-color: #F2FAFA;
  border: 1px solid #71C9D2;
  grid-template: 
    'info'
    'choose'
    'start-button';
}

.liveness-history {
  width: 50%;
  max-width: 350px;

  display: grid;
}

.search-list {
  justify-self: end;
}

.liveness-history-item {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 992px) {
  .liveness-card {
    width: 90%
  }
}