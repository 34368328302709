.big-header-bg {
  background: linear-gradient(45deg, #00708d 0%, #16a3b8 100%);
  height: 20vh;
  display: flex;
  align-items: center;
  padding-left: 5%;
  min-height: 120px;
}

.big-header-logo-brasil {
  height: 4.5vh;
  min-height: 35px;
}

.big-header-subtext {
  font-size: 100%;
}
